import type { TFunction } from '@mentimeter/i18n';
import type { UserPoliciesT } from '@mentimeter/user';
import { PlanCategory } from '@mentimeter/http-clients';
import type {
  LocalPricingT,
  UpgradeOptionsResponseT,
} from '@mentimeter/billing';
import { getFormattedPrice, CurrencyCode } from '@mentimeter/billing';
import type { PlanT } from './types';
import { BasicIcon, EnterpriseIcon, FreeIcon, ProIcon } from './icons';
import EngagementProgressIllustration from './illustrations/engagement_progress_illustration.png';

export interface GetPlansPreviewConfigT {
  isEducation: boolean;
  isFreeUser: boolean;
  userPolicies: Pick<
    UserPoliciesT,
    | 'isTrialUser'
    | 'isGenericProUser'
    | 'isGenericBasicYearlyUser'
    | 'isGenericBasicMonthlyUser'
    | 'isEnterpriseUser'
  >;
  prices: LocalPricingT;
  loggedIn: boolean;
  isPriceLoading: boolean;
  monthlyToggled: boolean;
  possibleUpgrades: UpgradeOptionsResponseT | undefined;
  freePlanAction: PlanT['action'];
  highlightedPlan: string;
  showRecommendedBadge: boolean;
  isInMonthlyTreatment: boolean;
  showLicenseSelector: boolean;
  showQuoteButton: boolean;
  isOnboarding: boolean;
  host: string;
  Link: React.ComponentType<{ href?: string; children?: React.ReactNode }>;
  trackPlan: (arg0: string) => void;
  t: TFunction;
  country?: string | null;
  userEngagementLimitProgress?: number | undefined;
}

interface PlansPreviewConfigT {
  plans: Array<PlanT>;
}

export function getPlansPreviewConfig({
  isEducation,
  isFreeUser,
  userPolicies,
  prices,
  loggedIn,
  isPriceLoading,
  monthlyToggled,
  possibleUpgrades,
  freePlanAction,
  highlightedPlan,
  showRecommendedBadge,
  isInMonthlyTreatment,
  showLicenseSelector,
  showQuoteButton,
  isOnboarding,
  host,
  trackPlan,
  t,
  country,
  userEngagementLimitProgress,
}: GetPlansPreviewConfigT): PlansPreviewConfigT {
  const {
    isGenericBasicMonthlyUser,
    isGenericBasicYearlyUser,
    isGenericProUser,
    isTrialUser,
    isEnterpriseUser,
  } = userPolicies;
  const countryIsNotUS = country && country !== 'US';
  const currencyIsUSD = prices?.currency?.code === CurrencyCode.USD;
  const pricePostfix = countryIsNotUS && currencyIsUSD ? CurrencyCode.USD : '';
  const userIsEngaged = (userEngagementLimitProgress ?? 0) > 0;

  function isPlanCategoryMatch(PLAN: string): boolean {
    // Show enabled buy buttons based on loggedin status and possible upgrades
    return (
      !loggedIn ||
      (possibleUpgrades?.upgrade_options?.some(
        (plan) => plan?.plan_category === PLAN,
      ) ??
        false)
    );
  }

  return {
    plans: [
      {
        id: 'free',
        category: PlanCategory.FREE,
        icon: FreeIcon,
        isCurrentSubscription: isFreeUser,
        heading: t('plansContentPackage:plans.free.heading'),
        subheading: [
          {
            text: freeSubheading(t),
          },
        ],
        currencySymbol: prices.currency.symbol,
        priceFormat: prices.format,
        recommended: showRecommendedBadge && highlightedPlan === 'free',
        highlight: highlightedPlan === 'free',
        decimalSeparator: prices?.format.decimalSeparator,
        price: prices ? getPlanPrice(prices) : '',
        amount: null,
        tiers: null,
        pricePostfix,
        showLicenseSelector: false,
        showQuoteButton: false,
        isPriceLoading,
        priceMeta: [
          {
            text: isEducation
              ? t('plansContentPackage:plans.education.no_credit_card_needed')
              : t('plansContentPackage:plans.no_credit_card_needed'),
          },
        ],
        action: freePlanAction,
        usp: freeUsps(t, {
          userIsEngaged,
        }),
        uspHeading: t('plansContentPackage:plans.free.usp_heading'),
        hideOnMobileOrTablet: false,
        track: () => trackPlan(isEducation ? 'educational-free' : 'free'),
      },
      {
        id: 'basic',
        currencySymbol: prices.currency.symbol,
        priceFormat: prices.format,
        category: isEducation
          ? monthlyToggled
            ? PlanCategory.EDUCATIONAL_BASIC_MONTHLY
            : PlanCategory.EDUCATIONAL_BASIC
          : monthlyToggled
            ? PlanCategory.BASIC_MONTHLY
            : PlanCategory.BASIC,
        icon: BasicIcon,
        isCurrentSubscription: monthlyToggled
          ? isGenericBasicMonthlyUser
          : isGenericBasicYearlyUser,
        isChangingBillingCycle: isGenericBasicMonthlyUser && !monthlyToggled,
        heading: t('plansContentPackage:plans.basic.heading'),
        subheading: [
          {
            text: basicSubheading(t),
          },
        ],
        recommended: showRecommendedBadge && highlightedPlan === 'basic',
        highlight: highlightedPlan === 'basic',
        decimalSeparator: prices?.format.decimalSeparator,
        price: prices
          ? getFormattedPricesForBasic({ isEducation, monthlyToggled, prices })
          : '',
        amount: getAmountsForBasic({ isEducation, monthlyToggled, prices }),
        tiers: prices?.plans[PlanCategory.BASIC].tiers,
        pricePostfix,
        showLicenseSelector,
        showQuoteButton,
        isPriceLoading,
        priceMeta: [
          {
            text: monthlyToggled
              ? t('plansContentPackage:plans.billed_monthly')
              : t('plansContentPackage:plans.billed_annually'),
            yearlyDiscount: !monthlyToggled && isInMonthlyTreatment,
          },
          {
            text: t('plansContentPackage:plans.price_per_presenter'),
            tooltips: [
              {
                target: t(
                  'plansContentPackage:plans.price_per_presenter_tooltip_target',
                ),
                text: t(
                  'plansContentPackage:plans.price_per_presenter_tooltip',
                ),
              },
            ],
          },
        ],
        newPriceMeta: [
          {
            text: showLicenseSelector
              ? ''
              : t('plansContentPackage:plans.price_per_presenter_per_month'),
          },
          {
            text: showLicenseSelector
              ? t(
                  `plansContentPackage:plans.pls_dropdown_billed_${monthlyToggled ? 'monthly' : 'annually'}`,
                )
              : monthlyToggled
                ? t('plansContentPackage:plans.billed_monthly_excl_tax')
                : t('plansContentPackage:plans.billed_annually_excl_tax'),
            yearlyDiscount: !monthlyToggled && isInMonthlyTreatment,
            yearlyDiscountText: t('plansContentPackage:plans.save_discount', {
              discount: '33%',
            }),
          },
        ],
        discount: isEducation
          ? {
              baseAmount: getAmountsForBasic({
                isEducation: false,
                monthlyToggled,
                prices,
              }),
              tiers: prices.plans[PlanCategory.BASIC].tiers,
              percent: t('plansContentPackage:plans.discount', {
                discount: '30%',
              }),
              save: t('plansContentPackage:plans.save_discount', {
                discount: '30%',
              }),
            }
          : undefined,
        action: {
          href: absoluteUrl(
            host,
            upgradeActionHref({ isEducation, monthlyToggled }),
          ),
          children: isGenericBasicYearlyUser
            ? t('plansContentPackage:plans.add_licenses')
            : isGenericBasicMonthlyUser && monthlyToggled
              ? t('plansContentPackage:plans.add_licenses')
              : isGenericBasicMonthlyUser && !monthlyToggled
                ? t('plansContentPackage:plans.switch_to_yearly')
                : isFreeUser
                  ? t('plansContentPackage:plans.basic.upgrade_to_basic')
                  : t('plansContentPackage:plans.basic.buy_basic'),
          disabled:
            isEnterpriseUser ||
            (!isTrialUser && isGenericProUser) ||
            !isPlanCategoryMatch(isEducation ? 'educational-basic' : 'basic'),
        },
        usp: basicUsps(t),
        uspHeading: t('plansContentPackage:plans.basic.usp_heading'),
        track: () => {
          if (isEducation) {
            return trackPlan(
              monthlyToggled && !isGenericBasicMonthlyUser
                ? 'educational-basic-monthly'
                : 'educational-basic',
            );
          } else {
            return trackPlan(
              monthlyToggled && !isGenericBasicMonthlyUser
                ? 'basic-monthly'
                : 'basic',
            );
          }
        },
      },
      {
        id: 'pro',
        amount:
          prices?.plans[
            isEducation ? PlanCategory.EDUCATIONAL_PRO : PlanCategory.PRO
          ].amount,
        category: isEducation ? PlanCategory.EDUCATIONAL_PRO : PlanCategory.PRO,
        icon: ProIcon,
        isCurrentSubscription: isGenericProUser,
        isDisabled: monthlyToggled,
        heading: t('plansContentPackage:plans.pro.heading'),
        subheading: [
          proSubheading(t, {
            isEducation,
            isOnboarding,
          }),
        ],
        recommended: showRecommendedBadge && highlightedPlan === 'pro',
        highlight: highlightedPlan === 'pro',
        decimalSeparator: prices?.format.decimalSeparator,
        price: prices
          ? getFormattedPrice(
              isEducation
                ? prices.plans[PlanCategory.EDUCATIONAL_PRO].amount
                : prices.plans[PlanCategory.PRO].amount,
              prices.currency.symbol,
              prices.format,
            )
          : '',
        tiers: isEducation
          ? prices.plans[PlanCategory.EDUCATIONAL_PRO].tiers
          : prices.plans[PlanCategory.PRO].tiers,
        currencySymbol: prices.currency.symbol,
        priceFormat: prices.format,
        pricePostfix,
        showLicenseSelector,
        showQuoteButton,
        isPriceLoading,
        action: {
          href: absoluteUrl(
            host,
            isEducation ? '/app/checkout/educational-pro' : '/app/checkout/pro',
          ),
          children: monthlyToggled
            ? t('plansContentPackage:plans.only_yearly')
            : isGenericProUser && !isTrialUser
              ? t('plansContentPackage:plans.add_licenses')
              : isFreeUser || isGenericBasicYearlyUser
                ? t('plansContentPackage:plans.pro.upgrade_to_pro')
                : t('plansContentPackage:plans.pro.buy_pro'),
          disabled:
            isEnterpriseUser ||
            monthlyToggled ||
            !isPlanCategoryMatch(isEducation ? 'educational-pro' : 'pro'),
        },
        usp: proUsps(t),
        priceMeta: [
          { text: t('plansContentPackage:plans.billed_annually') },
          {
            text: t('plansContentPackage:plans.price_per_presenter'),
            tooltips: [
              {
                target: t(
                  'plansContentPackage:plans.price_per_presenter_tooltip_target',
                ),
                text: t(
                  'plansContentPackage:plans.price_per_presenter_tooltip',
                ),
              },
            ],
          },
        ],
        newPriceMeta: [
          {
            text: showLicenseSelector
              ? ''
              : t('plansContentPackage:plans.price_per_presenter_per_month'),
          },
          {
            text: showLicenseSelector
              ? t('plansContentPackage:plans.pls_dropdown_billed_annually')
              : t('plansContentPackage:plans.billed_annually_excl_tax'),
          },
        ],
        discount: isEducation
          ? {
              baseAmount: prices ? prices.plans[PlanCategory.PRO].amount : null,
              tiers: prices.plans[PlanCategory.PRO].tiers,
              percent: t('plansContentPackage:plans.discount', {
                discount: '40%',
              }),
              save: t('plansContentPackage:plans.save_discount', {
                discount: '40%',
              }),
            }
          : undefined,
        uspHeading: t('plansContentPackage:plans.pro.usp_heading'),
        track: () => trackPlan(isEducation ? 'educational-pro' : 'pro'),
      },
      {
        id: 'enterprise',
        amount: null,
        category: PlanCategory.ENTERPRISE,
        icon: EnterpriseIcon,
        isDisabled: monthlyToggled,
        currencySymbol: prices.currency.symbol,
        priceFormat: prices.format,
        heading: isEducation
          ? t('plansContentPackage:plans.campus.heading')
          : t('plansContentPackage:plans.enterprise.heading'),
        subheading: [enterpriseSubheading(t)],
        recommended: showRecommendedBadge && highlightedPlan === 'enterprise',
        highlight: highlightedPlan === 'enterprise',
        price: t('plansContentPackage:plans.custom'),
        tiers: null,
        isPriceLoading: false,
        showLicenseSelector: false,
        showQuoteButton: false,
        smallerPrice: true,
        action: {
          href: isEducation
            ? absoluteUrl(host, '/education/enterprise')
            : absoluteUrl(host, '/enterprise'),
          children: monthlyToggled
            ? t('plansContentPackage:plans.only_yearly')
            : t('plansContentPackage:plans.learn_more'),
          disabled: monthlyToggled,
        },
        usp: isEducation
          ? [
              { text: t('plansContentPackage:plans.usps.sso') },
              { text: t('plansContentPackage:plans.scim') },
              { text: t('plansContentPackage:plans.usps.shared_templates') },
              { text: t('plansContentPackage:plans.usps.success_manager') },
            ]
          : [
              {
                text: t('plansContentPackage:plans.usps.company_wide_branding'),
              },
              { text: t('plansContentPackage:plans.usps.sso') },
              { text: t('plansContentPackage:plans.scim') },
              { text: t('plansContentPackage:plans.usps.success_manager') },
            ],
        decimalSeparator: prices?.format.decimalSeparator,
        meta: '',
        newPriceMeta: [],
        uspHeading: isEducation
          ? t('plansContentPackage:plans.campus.usp_heading')
          : t('plansContentPackage:plans.enterprise.usp_heading'),
        track: () =>
          trackPlan(isEducation ? 'educational-enterprise' : 'enterprise'),
      },
    ],
  };
}

function getPlanPrice(prices: LocalPricingT): string {
  const formattedPrice = getFormattedPrice(
    0,
    prices.currency.symbol,
    prices.format,
  );
  return formattedPrice;
}

function getAmountsForBasic({
  isEducation,
  monthlyToggled,
  prices,
}: {
  isEducation: boolean;
  monthlyToggled: boolean;
  prices: LocalPricingT;
}) {
  if (isEducation) {
    return monthlyToggled
      ? prices.plans[PlanCategory.EDUCATIONAL_BASIC_MONTHLY].amount!
      : prices.plans[PlanCategory.EDUCATIONAL_BASIC].amount;
  } else {
    return monthlyToggled
      ? prices.plans[PlanCategory.BASIC_MONTHLY].amount!
      : prices.plans[PlanCategory.BASIC].amount;
  }
}

function getFormattedPricesForBasic({
  isEducation,
  monthlyToggled,
  prices,
}: {
  isEducation: boolean;
  monthlyToggled: boolean;
  prices: LocalPricingT;
}) {
  if (isEducation) {
    return getFormattedPrice(
      getAmountsForBasic({ isEducation, monthlyToggled, prices }),
      prices.currency.symbol,
      prices.format,
    );
  } else {
    return getFormattedPrice(
      getAmountsForBasic({ isEducation, monthlyToggled, prices }),
      prices.currency.symbol,
      prices.format,
    );
  }
}

function upgradeActionHref({
  isEducation,
  monthlyToggled,
}: {
  isEducation: boolean;
  monthlyToggled: boolean;
}) {
  if (isEducation) {
    return monthlyToggled
      ? '/app/checkout/educational-basic-monthly'
      : '/app/checkout/educational-basic';
  } else {
    return monthlyToggled
      ? '/app/checkout/basic-monthly'
      : '/app/checkout/basic';
  }
}

function absoluteUrl(host: string, url: string): string {
  return host + url;
}

function freeSubheading(t: TFunction) {
  return t('plansContentPackage:plans.free.subheading_engage_audience');
}

function basicSubheading(t: TFunction) {
  return t('plansContentPackage:plans.basic.subheading_engage_audience');
}

function proSubheading(
  t: TFunction,
  {
    isEducation,
    isOnboarding,
  }: {
    isEducation: boolean;
    isOnboarding: boolean;
  },
) {
  if (isEducation) {
    return {
      text: t(
        'plansContentPackage:plans.pro.subheading_all_the_power_to_customize',
      ),
    };
  }
  if (isOnboarding) {
    return {
      text: t(
        'plansContentPackage:plans.pro.subheading_full_access_mentimeter',
      ),
    };
  }

  return {
    text: t('plansContentPackage:plans.pro.subheading_bring_engagement'),
  };
}

function enterpriseSubheading(t: TFunction) {
  return {
    text: t('plansContentPackage:plans.enterprise.subheading_engage_audience'),
  };
}

function freeUsps(
  t: TFunction,
  {
    userIsEngaged,
  }: {
    userIsEngaged: boolean;
  },
) {
  return [
    {
      text: t('plansContentPackage:plans.usps.participants_per_month', {
        participants: 50,
      }),
      subheading: {
        text: t('plansContentPackage:plans.usps.go_over_limit_once_monthly'),

        tooltips: [
          {
            target: t(
              'plansContentPackage:plans.usps.go_over_limit_once_monthly',
            ),
            text: userIsEngaged
              ? t(
                  'plansContentPackage:plans.usps.go_over_limit_once_monthly_description_engaged',
                )
              : t(
                  'plansContentPackage:plans.usps.go_over_limit_once_monthly_description_unengaged',
                ),
            illustrationUrl: EngagementProgressIllustration.src,
          },
        ],
        iconTooltip: {
          text: userIsEngaged
            ? t(
                'plansContentPackage:plans.usps.go_over_limit_once_monthly_description_engaged',
              )
            : t(
                'plansContentPackage:plans.usps.go_over_limit_once_monthly_description_unengaged',
              ),
          illustrationUrl: EngagementProgressIllustration.src,
        },
      },
      tooltips: [
        {
          target: t('plansContentPackage:plans.usps.participants_per_month', {
            participants: 50,
          }),
          text: t(
            'plansContentPackage:plans.participants_per_month_description_v2',
          ),
        },
      ],
    },
    {
      text: t('plansContentPackage:plans.usps.limited_engagement_slides'),
    },
    {
      text: t('plansContentPackage:plans.usps.co_edit_presentations_v2'),
    },
  ];
}

function basicUsps(t: TFunction) {
  return [
    {
      text: t('plansContentPackage:plans.usps.unlimited_participants'),
    },
    { text: t('plansContentPackage:plans.usps.import_slides') },
    { text: t('plansContentPackage:plans.usps.export_results_to_excel') },
  ];
}

function proUsps(t: TFunction) {
  return [
    {
      text: t('plansContentPackage:plans.usps.add_company_logo_and_branding'),
    },
    {
      text: t('plansContentPackage:plans.usps.create_team_templates'),
    },
    {
      text: t('plansContentPackage:plans.usps.co_edit_presentations_privately'),
      tooltips: [
        {
          target: t(
            'plansContentPackage:plans.usps.co_edit_presentations_privately',
          ),
          text: t(
            'plansContentPackage:plans.usps.co_edit_presentations_privately_description',
          ),
        },
      ],
    },
  ];
}
