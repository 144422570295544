import type React from 'react';
import type { TFunction } from '@mentimeter/i18n';
import type { UserPoliciesT } from '@mentimeter/user';
import type {
  LocalPricingT,
  UpgradeOptionsResponseT,
} from '@mentimeter/billing';
import type { FeatureT, PlansPageConfigT } from './types';
import { Italic, Bold } from './typography';
import { getPlansPreviewConfig } from './plansPreviewConfig';
import { getPlansFaqConfig } from './plansFaqConfig';
import EngagementProgressIllustration from './illustrations/engagement_progress_illustration.png';

export interface PlansPageConfigPropsT {
  isEducation: boolean;
  isFreeUser: boolean;
  loggedIn: boolean;
  userPolicies: Pick<
    UserPoliciesT,
    | 'isTrialUser'
    | 'isGenericProUser'
    | 'isGenericBasicUser'
    | 'isGenericBasicYearlyUser'
    | 'isGenericBasicMonthlyUser'
    | 'isEnterpriseUser'
  >;
  prices: LocalPricingT;
  isPriceLoading: boolean;
  monthlyToggled: boolean;
  possibleUpgrades: UpgradeOptionsResponseT | undefined;
  isInMonthlyTreatment: boolean;
  userEngagementLimitProgress: number | undefined;
  showLicenseSelector: boolean;
  showQuoteButton: boolean;
  toggleSwitch: () => void;
  host: string;
  Link: React.ComponentType<{ href?: string; children?: React.ReactNode }>;
  trackPlan: (arg0: string) => void;
  t: TFunction;
  country: string | null;
}

export const getPlansPageConfig = (
  props: PlansPageConfigPropsT,
): PlansPageConfigT => {
  const {
    isEducation,
    isFreeUser,
    loggedIn,
    userPolicies,
    prices,
    isPriceLoading,
    monthlyToggled,
    possibleUpgrades,
    isInMonthlyTreatment,
    showLicenseSelector,
    showQuoteButton,
    toggleSwitch,
    userEngagementLimitProgress,
    host,
    Link,
    trackPlan,
    t,
    country,
  } = props;
  const {
    isGenericBasicUser,
    isGenericProUser,
    isTrialUser,
    isEnterpriseUser,
  } = userPolicies;

  const plansPreviewConfig = getPlansPreviewConfig({
    isEducation,
    isFreeUser,
    userPolicies,
    prices,
    loggedIn,
    isPriceLoading,
    monthlyToggled,
    possibleUpgrades,
    highlightedPlan: monthlyToggled ? 'basic' : 'pro',
    showRecommendedBadge: true,
    freePlanAction: {
      href: loggedIn ? '/app' : '/auth/signup?referral=plans',
      children: isFreeUser
        ? t('plansContentPackage:plans.current_plan')
        : t('plansContentPackage:plans.get_started'),
      disabled:
        isFreeUser ||
        isGenericProUser ||
        isGenericBasicUser ||
        isTrialUser ||
        isEnterpriseUser,
    },
    isInMonthlyTreatment,
    showLicenseSelector,
    showQuoteButton,
    isOnboarding: false,
    userEngagementLimitProgress,
    host,
    Link,
    trackPlan,
    t,
    country,
  });

  return {
    toggleFunction: () => toggleSwitch(),
    monthlyToggled,
    isInMonthlyTreatment,
    showLicenseSelector,
    showQuoteButton,
    title: isEducation
      ? [
          t(
            'plansContentPackage:plans.education.boost_engagement_in_the_classroom.0',
          ),
          t(
            'plansContentPackage:plans.education.boost_engagement_in_the_classroom.1',
          ),
        ]
      : [
          t('plansContentPackage:plans.heading_give_voice.0'),
          t('plansContentPackage:plans.heading_give_voice.1'),
        ],
    pageTitle: isEducation
      ? t('plansContentPackage:plans.education.educational_pricing')
      : t('plansContentPackage:plans.page_title'),
    description: isEducation
      ? t('plansContentPackage:plans.education.page_description')
      : t('plansContentPackage:plans.page_description'),
    isEducation,
    plans: plansPreviewConfig.plans,
    plansFeatures: [
      {
        section: t('plansContentPackage:plans.presentations'),
        features: [
          {
            heading: t('plansContentPackage:plans.participants_per_month'),
            description: t(
              'plansContentPackage:plans.participants_per_month_description_v2',
            ),

            illustrationUrl: EngagementProgressIllustration.src,

            free: '50',
            basic: t('plansContentPackage:plans.unlimited'),
            pro: t('plansContentPackage:plans.unlimited'),
            enterprise: t('plansContentPackage:plans.unlimited'),
          },
          {
            heading: t('plansContentPackage:plans.slide_types'),
            description: t('plansContentPackage:plans.slide_types_description'),
            free: '34',
            basic: t('plansContentPackage:plans.unlimited'),
            pro: t('plansContentPackage:plans.unlimited'),
            enterprise: t('plansContentPackage:plans.unlimited'),
          },
          {
            heading: t('plansContentPackage:plans.q&a'),
            description: t(
              'plansContentPackage:plans.crowdsource_questions_from_audience',
            ),
            free: true,
            basic: true,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plansContentPackage:plans.usps.import_presentations'),
            description: t(
              'plansContentPackage:plans.import_questions_from_pp_keynote_google',
            ),
            free: false,
            basic: true,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plansContentPackage:plans.make_presentations_private'),
            description: t(
              'plansContentPackage:plans.make_presentations_private_description',
            ),
            free: false,
            basic: true,
            pro: true,
            enterprise: true,
          },
        ].filter(Boolean) as FeatureT[],
      },
      {
        section: t('plansContentPackage:plans.branding_and_customization'),
        features: [
          {
            heading: t('plansContentPackage:plans.quick_layouts'),
            description: t('plansContentPackage:plans.quick_layers_heading'),
            free: true,
            basic: true,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plansContentPackage:plans.custom_colors'),
            description: t(
              'plansContentPackage:plans.custom_colors_description',
            ),
            free: false,
            basic: false,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plansContentPackage:plans.custom_themes'),
            description: t(
              'plansContentPackage:plans.custom_themes_description',
            ),
            free: false,
            basic: false,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plansContentPackage:plans.usps.company_branding'),
            description: t(
              'plansContentPackage:plans.company_branding_description',
            ),
            free: false,
            basic: false,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plansContentPackage:plans.add_logotype'),
            description: '',
            free: false,
            basic: false,
            pro: true,
            enterprise: true,
          },
        ],
      },
      {
        section: t('plansContentPackage:plans.teams'),
        features: [
          {
            heading: t(
              'plansContentPackage:plans.teams_collaborative_workspace',
            ),
            description: t(
              'plansContentPackage:plans.teams_collaborative_workspace_description',
            ),
            free: true,
            basic: true,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plansContentPackage:plans.teams_workspace_roles'),
            description: t(
              'plansContentPackage:plans.teams_workspace_roles_description',
            ),
            free: false,
            basic: true,
            pro: true,
            enterprise: true,
          },
          {
            heading: t(
              'plansContentPackage:plans.teams_maximum_number_of_member_lite',
            ),
            description: t(
              'plansContentPackage:plans.teams_maximum_number_of_member_lite_description',
            ),
            free: 'N/A',
            basic: '20',
            pro: '20',
            enterprise: t('plansContentPackage:plans.custom'),
          },
          {
            heading: t(
              'plansContentPackage:plans.collaborate_on_presentations',
            ),
            description: t(
              'plansContentPackage:plans.collaborate_on_presentations_description',
            ),
            free: true,
            basic: true,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plansContentPackage:plans.invite_non_members'),
            description: t(
              'plansContentPackage:plans.invite_non_members_description',
            ),
            free: false,
            basic: false,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plansContentPackage:plans.teams_header'),
            description: t('plansContentPackage:plans.teams_description'),
            free: false,
            basic: false,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plansContentPackage:plans.teams_create_groups'),
            description: t(
              'plansContentPackage:plans.teams_create_groups_description',
            ),
            free: false,
            basic: false,
            pro: false,
            enterprise: true,
          },
          {
            heading: t('plansContentPackage:plans.teams_workspace_insights'),
            description: t(
              'plansContentPackage:plans.teams_workspace_insights_description',
            ),
            free: false,
            basic: false,
            pro: false,
            enterprise: true,
          },
        ],
      },
      {
        section: t('plansContentPackage:plans.export'),
        features: [
          {
            heading: t('plansContentPackage:plans.export_heading'),
            description: '',
            free: true,
            basic: true,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plansContentPackage:plans.export_excel'),
            description: '',
            free: false,
            basic: true,
            pro: true,
            enterprise: true,
          },
        ],
      },
      {
        section: t('plansContentPackage:plans.advanced_input'),
        features: [
          {
            heading: t('plansContentPackage:plans.moderate_q&a'),
            description: t(
              'plansContentPackage:plans.moderate_q&a_description',
            ),
            free: false,
            basic: false,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plansContentPackage:plans.add_quick_forms'),
            description: t(
              'plansContentPackage:plans.add_quick_forms_description',
            ),
            free: false,
            basic: false,
            pro: true,
            enterprise: true,
          },
          {
            heading: t(
              'plansContentPackage:plans.several_answers_from_one_device',
            ),
            description: t(
              'plansContentPackage:plans.several_answers_from_one_device_description',
            ),
            free: false,
            basic: false,
            pro: true,
            enterprise: true,
          },
        ],
      },
      {
        section: t('plansContentPackage:plans.privacy_and_control'),
        features: [
          {
            heading: t('plansContentPackage:plans.access_level'),
            description: t('plansContentPackage:plans.assign_admins'),
            free: false,
            basic: true,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plansContentPackage:plans.join_settings'),
            description: t(
              'plansContentPackage:plans.customize_who_can_join_organisation',
            ),
            free: false,
            basic: false,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plansContentPackage:plans.sso'),
            description: t('plansContentPackage:plans.saml'),
            free: false,
            basic: false,
            pro: false,
            enterprise: true,
          },
          {
            heading: t('plansContentPackage:plans.scim'),
            description: t('plansContentPackage:plans.scim_description'),
            free: false,
            basic: false,
            pro: false,
            enterprise: true,
          },
          {
            heading: t('plansContentPackage:plans.custom_data_retention'),
            description: t(
              'plansContentPackage:plans.custom_data_retention_description',
            ),
            free: false,
            basic: false,
            pro: false,
            enterprise: true,
          },
        ],
      },
      {
        section: t('plansContentPackage:plans.payment'),
        features: [
          {
            heading: t('plansContentPackage:plans.payment_bank_transfer'),
            description: t(
              'plansContentPackage:plans.payment_bank_transfer_description',
            ),
            free: false,
            basic: false,
            pro: true,
            enterprise: true,
          },
        ],
      },
      {
        section: t('plansContentPackage:plans.support'),
        features: [
          {
            heading: t('plansContentPackage:plans.online_support'),
            description: '',
            free: t('plansContentPackage:plans.help_center'),
            basic: true,
            pro: true,
            enterprise: true,
          },
          {
            heading: t('plansContentPackage:plans.priority_support'),
            description: t(
              'plansContentPackage:plans.priority_support_description',
            ),
            free: false,
            basic: false,
            pro: false,
            enterprise: true,
          },
          {
            heading: t('plansContentPackage:plans.custom_onboarding'),
            description: t(
              'plansContentPackage:plans.custom_onboarding_description',
            ),
            free: false,
            basic: false,
            pro: false,
            enterprise: true,
          },
        ],
      },
    ],
    faq: getPlansFaqConfig({
      t,
      Link,
      Italic,
      Bold,
      isInMonthlyTreatment,
      isEducation,
    }),
    conferenceBanner: {
      heading: t('plansContentPackage:plans.conference_banner.heading'),
      description: t('plansContentPackage:plans.conference_banner.description'),
      actionText: t('plansContentPackage:plans.conference_banner.action_text'),
    },
  };
};
