import type { TextT } from '@mentimeter/ragnar-ui';
import { Text } from '@mentimeter/ragnar-ui';
import React from 'react';

export const Bold = (props: TextT) => (
  <Text
    as="span"
    fontWeight="semiBold"
    fontSize="inherit"
    lineHeight="inherit"
    color="inherit"
    {...props}
  />
);

export const Italic = (props: TextT) => (
  <Text
    as="span"
    fontStyle="italic"
    fontSize="inherit"
    lineHeight="inherit"
    color="inherit"
    {...props}
  />
);
