import type { TFunction } from '@mentimeter/i18n';
import { Trans } from '@mentimeter/i18n';
import React, { type JSX } from 'react';
import type { TextT } from '@mentimeter/ragnar-ui';
import type { FaqT } from './types';

export function getPlansFaqConfig({
  t,
  Link,
  Italic,
  Bold,
  isInMonthlyTreatment,
  isEducation,
}: {
  t: TFunction;
  Link: React.ComponentType<{ href?: string; children?: React.ReactNode }>;
  Italic: (props: TextT) => JSX.Element;
  Bold: (props: TextT) => JSX.Element;
  isInMonthlyTreatment: boolean;
  isEducation: boolean;
}): FaqT {
  return [
    {
      question: t('plansContentPackage:plans.faq.can_i_pay_with_invoice'),
      answer: (
        <Trans
          t={t}
          i18nKey="plansContentPackage:plans.faq.can_i_pay_with_invoice_answer"
        >
          Yes, you can pay for a Pro or Enterprise plan via bank transfer. We
          offer Net-30 payment terms via bank transfer for Pro subscriptions in
          selected countries. Your account will be suspended if we don’t receive
          the funds within 30 days. Additional administrative fees apply.
          <Bold>We do not accept checks.</Bold>
          The first step is to
          <Link href="/app/invoice-request">request an invoice</Link>.
        </Trans>
      ),
    },
    {
      question: t('plansContentPackage:plans.faq.why_is_mentimeter_free'),
      answer: t('plansContentPackage:plans.faq.why_is_mentimeter_free_answer'),
    },
    {
      question: t(
        'plansContentPackage:plans.faq.what_is_participants_per_month',
      ),
      answer: t(
        'plansContentPackage:plans.faq.what_is_participants_per_month_answer',
      ),
    },
    {
      question: t('plansContentPackage:plans.faq.monthly_participants_limit'),
      answer: t(
        'plansContentPackage:plans.faq.monthly_participants_limit_answer',
      ),
    },
    {
      question: t('plansContentPackage:plans.faq.alternative_payment_options'),
      answer: (
        <Trans
          t={t}
          i18nKey="plansContentPackage:plans.faq.alternative_payment_options_answer"
        >
          You can purchase all subscriptions with a credit card, debit card or
          PayPal. For Mentimeter Pro we can offer invoices payable through bank
          transfer (Swedish Bank Giro, or IBAN and SWIFT for international
          payments) as a payment option. Payment is due 30 after the invoice is
          sent. To request an invoice to pay via bank transfer,
          <Link href="/app/invoice-request">click here</Link>.
          <Bold>We do not accept checks.</Bold>
        </Trans>
      ),
    },
    {
      question: t('plansContentPackage:plans.faq.will_you_store_credit_card'),
      answer: (
        <Trans
          t={t}
          i18nKey="plansContentPackage:plans.faq.will_you_store_credit_card_answer"
        >
          We do not see or process your credit card information. All payment
          details are handled by our payment providers (Braintree and Stripe) to
          maximize security. Both have extensive documentation about their data
          security procedures.
          <Link href="https://www.braintreepayments.com/products-and-features/data-security">
            https://www.braintreepayments.com/se/features/data-security
          </Link>
          ,
          <Link href="https://stripe.com/docs/security">
            https://stripe.com/docs/security
          </Link>
        </Trans>
      ),
    },
    isInMonthlyTreatment
      ? {
          question: t('plansContentPackage:plans.faq.pay_for_one_month'),
          answer: (
            <Trans
              t={t}
              i18nKey="plansContentPackage:plans.faq.pay_for_one_month_monthly_billing_answer"
            >
              Our Basic plan is available for monthly billing, but you are not
              able to pay for one month of Pro or Enterprise. We also offer a
              conference package that gives you access to pro features for
              multiple presenters for one-time events. Read more about our
              <Link href="/plans/conference">Conference deals here</Link>.
            </Trans>
          ),
        }
      : {
          question: t('plansContentPackage:plans.faq.pay_for_one_month'),
          answer: (
            <Trans
              t={t}
              i18nKey="plansContentPackage:plans.faq.pay_for_one_month_answer"
            >
              No. For Mentimeter Pro and Basic, we only offer yearly
              subscriptions. If you really, really need Mentimeter only for a
              one-time event, such as a conference, we have you covered. Read
              more about our
              <Link href="/plans/conference">Conference deals here</Link>.
            </Trans>
          ),
        },
    {
      question: t('plansContentPackage:plans.faq.why_annual_payments'),
      answer: t('plansContentPackage:plans.faq.why_annual_payments_answer'),
    },
    {
      question: t('plansContentPackage:plans.faq.change_mind'),
      answer: t('plansContentPackage:plans.faq.change_mind_answer'),
    },
    ...(isEducation
      ? [
          {
            question: t(
              'plansContentPackage:plans.education.do_you_have_special_pricing_for_k-12',
            ),
            answer: (
              <Trans
                t={t}
                i18nKey="plansContentPackage:plans.education.do_you_have_special_pricing_for_k-12_answer"
              >
                For individual licenses, see the <Bold>plan options</Bold> at
                the top of this page. To enquire for 10 or more licenses, please
                contact us at custom@mentimeter.com to request a quote.
              </Trans>
            ),
          },
          {
            question: t(
              'plansContentPackage:plans.education.do_you_have_a_discount_for_non-profit_organizations',
            ),
            answer: (
              <Trans
                t={t}
                i18nKey="plansContentPackage:plans.education.do_you_have_a_discount_for_non-profit_organizations_answer"
              >
                Please contact us at
                <Link href="mailto:hello@mentimeter.com">
                  hello@mentimeter.com
                </Link>
                with a short description of the organization.
              </Trans>
            ),
          },
        ]
      : [
          {
            question: t('plansContentPackage:plans.faq.educational_pricing'),
            answer: (
              <Trans
                t={t}
                i18nKey="plansContentPackage:plans.faq.educational_pricing_answer"
              >
                Yes -
                <Link href="/plans/education">
                  see our discounted offers for educators
                </Link>
                .
              </Trans>
            ),
          },
        ]),
    {
      question: t('plansContentPackage:plans.faq.which_types_of_questions'),
      answer: (
        <Trans
          t={t}
          i18nKey="plansContentPackage:plans.faq.which_types_of_questions_answer"
        >
          Mentimeter features many different ways in which you can interact with
          your audience. Ranging from standard Multiple Choice and Open Ended
          questions, you can also create Word Clouds or play Quizzes with your
          audience. See our
          <Link href="/features">features page</Link> orMentimeter features many
          different ways
          <Link href="/guides">our guides</Link> for more information about the
          different question types.
        </Trans>
      ),
    },
    {
      question: t('plansContentPackage:plans.faq.quiz_question_difference'),
      answer: (
        <Trans
          t={t}
          i18nKey="plansContentPackage:plans.faq.quiz_question_difference_answer_series_limit_experiment"
        >
          Quiz is one of our question types, but it works a bit differently. The
          <Link href="/campaigns/quiz-energy-level">Quiz is a competition</Link>
          , and the results from all Quiz questions in one presentation will add
          up to determine one final winner!
        </Trans>
      ),
    },
    {
      question: t('plansContentPackage:plans.faq.exported_result_looks'),
      answer: (
        <Trans
          t={t}
          i18nKey="plansContentPackage:plans.faq.exported_result_looks_answer"
        >
          Exporting your result to Excel lets you further analyze the data
          afterwards, either per question or per voter. You can read more about
          exporting in our
          <Link href="https://help.mentimeter.com/after-the-presentation/export-results-to-excel?_ga=2.29792253.373225288.1553495870-365774505.1529394414">
            Help Center
          </Link>
          !
        </Trans>
      ),
    },
    {
      question: t('plansContentPackage:plans.faq.customize_and_brand'),
      answer: t('plansContentPackage:plans.faq.customize_and_brand_answer'),
    },
    {
      question: t('plansContentPackage:plans.faq.share_login'),
      answer: t('plansContentPackage:plans.faq.share_login_answer'),
    },
    {
      question: t(
        'plansContentPackage:plans.faq.same_presentation_several_locations',
      ),
      answer: (
        <Trans
          t={t}
          i18nKey="plansContentPackage:plans.faq.same_presentation_several_locations_answer"
        >
          Yes, it is possible if you have a <Bold>Pro account</Bold> and share
          the presentation within your team so that it can be accessed from
          different accounts.
        </Trans>
      ),
    },
  ];
}
