import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useVisitorSplits } from 'src/split-hooks';
import { getSplitDataSync } from '@mentimeter/splitio';
import { userPolicies, useUser } from '@mentimeter/user';
import type { PlansPageConfigT } from '@mentimeter/plans-content';
import { getPlansPageConfig } from '@mentimeter/plans-content';
import { Link } from 'src/ui/actions';
import { gtmTrack } from '@mentimeter/google-tracking';
import {
  billingRules,
  DEFAULT_PRICE,
  useSubscriptions,
  useUpgradeOptions,
} from '@mentimeter/billing';
import { trackVisitor } from 'src/trackVisitor';
import { useWorkspace } from '@mentimeter/workspace-data-hooks';
import { useEngagementLimits } from '@mentimeter/paywalls-data-hooks';
import { trackUser } from '@api/tracking/client';
import { usePrices } from './usePrices';
import { useLicenseSelectorSplit } from './useLicenseSelectorSplit';
import { useQuoteButtonSplit } from './useQuoteButtonSplit';

export const useExperimentParameters = () => {
  const { Monthly_Billing } = useVisitorSplits(['Monthly_Billing']);

  const { isInLicenseSelectorTreatment, licenseSelectorExperimentIsReady } =
    useLicenseSelectorSplit();

  const { isInQuoteButtonTreatment, quoteButtonExperimentIsReady } =
    useQuoteButtonSplit();

  const isInMonthlyTreatment = Monthly_Billing === 'on';

  const isReady =
    Monthly_Billing !== 'not_ready' &&
    licenseSelectorExperimentIsReady &&
    quoteButtonExperimentIsReady;

  return {
    isReady,
    isInMonthlyTreatment,
    isInLicenseSelectorTreatment,
    isInQuoteButtonTreatment,
  };
};

export const usePlansConfig = ({ isEducation }: { isEducation: boolean }) => {
  const [monthlyToggled, setMonthlyToggled] = React.useState(false);
  const { user } = useUser();
  const isFreeUser = user?.isFreeUser ?? false;
  const { subscriptions } = useSubscriptions(!user);
  const { planName } = billingRules(subscriptions);
  const { data: workspace } = useWorkspace();
  const { country, preferredLanguage } = getSplitDataSync();
  const { t } = useTranslation();
  const isLoggedIn = Boolean(user);
  const {
    isReady,
    isInMonthlyTreatment,
    isInLicenseSelectorTreatment,
    isInQuoteButtonTreatment,
  } = useExperimentParameters();
  const engagementLimits = useEngagementLimits();
  const { isBasicMonthlyUser } = userPolicies(subscriptions);
  const {
    prices: dynamicPrices,
    error: priceError,
    isLoading: isPriceLoading,
  } = usePrices();
  const { possibleUpgrades } = useUpgradeOptions(!user);
  const showDynamicPrices = !priceError && !isPriceLoading;
  const trackPlan = React.useCallback(
    (plan: string) => {
      const current = planName || 'registered';

      const switchToYearlyPayload = {
        event: 'Clicked switch to yearly',
        properties: {
          context: 'Plans page',
        },
      };

      const buyPlanPayload = {
        event: 'Clicked buy plan',
        properties: {
          plan,
          context: 'Plans page',
          'current plan': current.toLowerCase(),
          'active team members': workspace?.memberStats?.active || 0,
          'engaged participants': engagementLimits?.engagementData.progress,
        },
      };

      const payload = isBasicMonthlyUser
        ? switchToYearlyPayload
        : buyPlanPayload;

      gtmTrack({
        event: 'selectProduct',
        type: plan,
        currentMembership: planName,
      });
      if (user) {
        trackUser(payload);
      } else {
        trackVisitor(payload);
      }

      if (plan === 'enterprise' || plan === 'educational-enterprise') {
        trackVisitor({ event: 'Clicked new enterprise plans copy' });
      }
    },
    [
      planName,
      workspace?.memberStats?.active,
      engagementLimits?.engagementData.progress,
      isBasicMonthlyUser,
      user,
    ],
  );

  const toggleSwitch = React.useCallback(() => {
    const payload = {
      event: 'Clicked billing toggle',
      properties: {
        context: 'Plans page',
        cycle: monthlyToggled ? 'yearly' : 'monthly',
      },
    };
    if (user) {
      trackUser(payload);
    } else {
      trackVisitor(payload);
    }
    setMonthlyToggled((value) => !value);
  }, [user, monthlyToggled]);

  const [config, setConfig] = React.useState<PlansPageConfigT>(
    getPlansPageConfig({
      isEducation,
      isFreeUser: false,
      loggedIn: isLoggedIn,
      userPolicies: userPolicies(subscriptions),
      prices:
        showDynamicPrices && dynamicPrices ? dynamicPrices : DEFAULT_PRICE,
      isPriceLoading,
      monthlyToggled,
      possibleUpgrades,
      isInMonthlyTreatment,
      showLicenseSelector: isInLicenseSelectorTreatment,
      showQuoteButton: isInQuoteButtonTreatment,
      toggleSwitch,
      userEngagementLimitProgress:
        engagementLimits?.engagementData.progress ?? undefined,
      host: '', // Note 2022-08-31: Buttons in content cannot handle absolute URLs, and require relative URLs even for redirecting to another application.
      Link,
      trackPlan,
      t,
      country,
    }),
  );

  React.useEffect(() => {
    setConfig(
      getPlansPageConfig({
        isEducation,
        isFreeUser,
        loggedIn: isLoggedIn,
        userPolicies: userPolicies(subscriptions),
        prices:
          showDynamicPrices && dynamicPrices ? dynamicPrices : DEFAULT_PRICE,
        isPriceLoading,
        monthlyToggled,
        possibleUpgrades,
        isInMonthlyTreatment,
        showLicenseSelector: isInLicenseSelectorTreatment,
        showQuoteButton: isInQuoteButtonTreatment,
        toggleSwitch,
        userEngagementLimitProgress:
          engagementLimits?.engagementData.progress ?? undefined,
        host: '', // Note 2022-08-31: Buttons in content cannot handle absolute URLs, and require relative URLs even for redirecting to another application.
        Link,
        trackPlan,
        t,
        country,
      }),
    );
  }, [
    user,
    isReady,
    monthlyToggled,
    possibleUpgrades,
    preferredLanguage,
    country,
    isEducation,
    isFreeUser,
    t,
    trackPlan,
    toggleSwitch,
    isInMonthlyTreatment,
    isLoggedIn,
    dynamicPrices,
    priceError,
    isPriceLoading,
    isInLicenseSelectorTreatment,
    engagementLimits?.engagementData.progress,
    isInQuoteButtonTreatment,
    subscriptions,
    showDynamicPrices,
  ]);

  return config;
};
